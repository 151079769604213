import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import user from '../images/user.jpg'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import required modules
import { Pagination } from 'swiper/modules';

// Functional Component
const Testimonial = () => {
  // Testimonial data
  const testimonials = [
    {
      name: 'John Doe',
      content: 'This service was amazing! Highly recommend to anyone.',
      image: user, // Replace with real image URLs
    },
    {
      name: 'Jane Smith',
      content: 'Fantastic experience from start to finish.',
      image: user,
    },
    {
      name: 'Sam Wilson',
      content: 'I am so impressed by the quality of the product.',
      image: user,
    },
    {
      name: 'Emily Johnson',
      content: 'Great customer support and reliable service.',
      image: user,
    },
  ];

  // Return the Swiper JSX
  return (
    <div className="testimonial-container container">
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        modules={[Pagination]}
        className="testimonialSwiper"
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} className="testimonial-slide">
            <div className="testimonial-content">
              <img
                src={testimonial.image}
                alt={`${testimonial.name}'s avatar`}
                className="testimonial-avatar"
              />
              <p className="testimonial-text">"{testimonial.content}"</p>
              <h4 className="testimonial-name">- {testimonial.name}</h4>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonial;
