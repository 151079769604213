import React from 'react'

import img1  from '../images/ayodhya-varanasi-prayagraj-chitrakoot.png'
import img2  from '../images/ayodhya-varanasi.png'
import img3  from '../images/ayodhya.png'
import img4  from '../images/varanasi.png'
import { Link } from 'react-router-dom'

const aboutUsSection = () => {
  return (
    <div>
        <div>
      <section className="ftco-section services-section">
			<div className="container">
				<div className="row d-flex">
					<div className="col-md-6 order-md-last heading-section pl-md-5  d-flex align-items-center">
						<div className="w-100">
							<span className="subheading">Welcome to Mohit Tour and Travels</span>
							<h2 className="mb-4">Travelling with Your Trusted Partner for Memorable Experiences</h2>
							<p>At Mohit Tour &amp; Travels, we provide unique and memorable travel experiences around India&#39;s most
revered cultural locations. We offer something for everyone, whether you are looking for an exciting
experience, a pilgrimage tour, or a tranquil retreat. Our expert staff is dedicated to providing you
with individualized services to make your journey hassle-free, enjoyable, and comfortable.</p>
							<p><Link to={'/productlist'} className="btn btn-primary py-3 px-4">Search Destination</Link></p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12 col-lg-6 d-flex align-self-stretch ">
								<div className="services services-1 color-1 d-block img" style={{
        backgroundImage: `url(${img1})`,
       
      }}>
									{/* <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-paragliding"></span></div> */}
									<div className="media-body">
										<h3 className="heading mb-3"> Varanasi</h3>
										<p>Known as the spiritual capital of India, Varanasi is famous for its ghats along the River Ganges.
										</p>
									</div>
								</div>      
							</div>
							<div className="col-md-12 col-lg-6 d-flex align-self-stretch ">
								<div className="services services-1 color-2 d-block img" style={{
        backgroundImage: `url(${img2})`,
       
      }}>
									{/* <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div> */}
									<div className="media-body">
										<h3 className="heading mb-3">Prayagraj</h3>
										<p>Prayagraj, formerly Allahabad, is renowned for the Triveni Sangam, the confluence of three holy rivers</p>
									</div>
								</div>    
							</div>
							<div className="col-md-12 col-lg-6 d-flex align-self-stretch ">
								<div className="services services-1 color-3 d-block img" style={{
        backgroundImage: `url(${img3})`,
       
      }}>
									{/* <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-tour-guide"></span></div> */}
									<div className="media-body">
										<h3 className="heading mb-3">Ayodhya</h3>
										<p>Ayodhya, the birthplace of Lord Ram, is a sacred city steeped in spiritual and cultural significance.</p>
									</div>
								</div>      
							</div>
							<div className="col-md-12 col-lg-6 d-flex align-self-stretch ">
								<div className="services services-1 color-4 d-block img" style={{
        backgroundImage: `url(${img4})`,
       
      }}>
									{/* <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-map"></span></div> */}
									<div className="media-body">
										<h3 className="heading mb-3">Chitrakoot</h3>
										<p>A picturesque destination, Chitrakoot is known for its association with the Ramayana.</p>
									</div>
								</div>      
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
    </div>
  )
}

export default aboutUsSection
