import React from 'react'

const MarketArea = () => {
  return (
    <div>
        <h1>Market Area</h1>
        <p>This is a paragraph about the market area.</p>
            
      
    </div>
  )
}

export default MarketArea
