import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ayodhaya from './dummyimages/ayodhya.png'
import ayodhayaV from './dummyimages/ayodhya-varanasi.png'
import ayodhayavp from './dummyimages/ayodhya-varanasi-prayagraj-chitrakoot.png'
import varanashi from './dummyimages/varanasi.png'


const DemoProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Simulate fetching data from an API with your dummy data
    const dummyData = [
      {
        "_id": 1,
        "image": ayodhaya,
        "name": "Varanasi (1 Night / 2 Days)",
        "details": "This is a high-quality product from website 1.",
        "price": 2999,
        "website": "https://website1.com"
      },
      {
        "_id": 2,
        "image": ayodhayaV,
        "name": "Product 2",
        "details": "Exclusive product with premium features from website 2.",
        "price": 2999,
        "website": "https://website2.com"
      },
      {
        "_id": 3,
        "image": ayodhayavp,
        "name": "Product 3",
        "details": "Affordable product with excellent reviews from website 3.",
        "price": 5999,
        "website": "https://website3.com"
      },
      {
        "_id": 4,
        "image": varanashi,
        "name": "Product 4",
        "details": "Best-seller product available on website 4.",
        "price": 9999,
        "website": "https://website4.com"
      },
      {
        "_id": 5,
        "image": ayodhaya,
        "name": "Product 5",
        "details": "Eco-friendly product featured on website 5.",
        "price": 4999,
        "website": "https://website5.com"
      },
      {
        "_id": 6,
        "image": ayodhayaV,
        "name": "Product 6",
        "details": "Innovative product designed for modern needs, from website 6.",
        "price": 7499,
        "website": "https://website6.com"
      },
      {
        "_id": 7,
        "image": ayodhayavp,
        "name": "Product 7",
        "details": "Top-rated product with advanced features from website 7.",
        "price": 8999,
        "website": "https://website7.com"
      },
      {
        "_id": 8,
        "image": varanashi,
        "name": "Product 8",
        "details": "Durable and reliable product from website 8.",
        "price": 11999,
        "website": "https://website8.com"
      },
      {
        "_id": 9,
        "image": ayodhaya,
        "name": "Product 9",
        "details": "Luxury product for discerning customers from website 9.",
        "price": 6500,
        "website": "https://website9.com"
      },
      {
        "_id": 10,
        "image": ayodhayavp,
        "name": "Product 10",
        "details": "Compact and versatile product from website 10.",
        "price": 8999,
        "website": "https://website10.com"
      }
    ];

    setProducts(dummyData);
  }, []);

  if (products.length === 0) return <div>Loading...</div>;

  return (
    <div className="container" style={{ marginTop: '50px', marginBottom: '50px' }}>
      <center><h2 className="mb-4">Our Tour Packages</h2></center>
      <hr />
      <div className="row d-flex justify-content-center">
        {products.map(product => (
          <div className="col-md-4 mb-4" key={product._id}>
            <div className="project-wrap">
              <div className="card">
                <img
                  src={product.image}
                  alt={product.name}
                  className="card-img-top"
                  style={{ height: '250px', objectFit: 'cover' }}
                />
                <div className="card-body text">
                  <h5 className="card-title">{product.name}</h5>
                  <h6 className="">Price: ₹{product.price}</h6>
                  {/* <p className="card-text">{product.details}</p> */}
                  <a
                    href={product.website}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book Now!
                  </a>
                  <Link to={`/product/${product._id}`} className="btn btn-secondary ms-2">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DemoProductList;
