import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CategorySection = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  // Fetch category data from the API
  useEffect(() => {
    axios.get('http://localhost:5000/category')
      .then(response => {
        setCategories(response.data);
        console.log(categories)
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  // Navigate to the products page for a specific category
  const handleCategoryClick = (categoryId) => {
    navigate(`/category/${categoryId}`);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Our Tour Packages</h2>
      <Row>
        {categories.map(category => (
          <Col 
            key={category._id} 
            xs={12} sm={6} md={4} lg={4} 
            className="mb-4" 
            onClick={() => handleCategoryClick(category._id)} 
            style={{ cursor: 'pointer' }}
          >
            <div className="project-wrap">
              <Card className="border-0">
                <Card.Img 
                  variant="top" 
                  className="img"
                  src={
                    category.images && category.images[0] 
                      ? `${category.images[0]}`
                      : 'default-image.jpg' // Fallback image when images are undefined or empty
                  }
                />
                <Card.Body>
                  <div className="text p-4">
                    <Card.Title>{category.name}</Card.Title>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        ))}
      </Row>

      
    </div>
  );
};

export default CategorySection;
