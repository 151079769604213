import React from 'react';
import bgImage from "../images/2151765561.jpg";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <footer
        className="ftco-footer bg-bottom ftco-no-pt"
        style={{
          position: 'relative',
          height: 'auto',
        }}
      >
        {/* Background image */}
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1, // Ensure the image stays in the background
          }}
        ></div>

        {/* Black overlay */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            opacity: 0.6, // Adjust opacity as needed
            zIndex: 0, // Ensure the overlay is above the image but below content
          }}
        ></div>

        {/* Footer content */}
        <div className="container-fluid px-5" style={{ position: 'relative', zIndex: 1 }}>
          <div className="row mb-5">
            <div className="col-md pt-5">
              <div className="ftco-footer-widget pt-md-5 mb-4">
                <h2 className="ftco-heading-2 text-white">Welcome to Mohit Tour and Travels</h2>
                <p className='text-white'>
                  At Mohit Tour &amp; Travels, we provide unique and memorable travel experiences around India&#39;s
                  most revered cultural locations. We offer something for everyone, whether you are looking for an
                  exciting experience, a pilgrimage tour, or a tranquil retreat. Our expert staff is dedicated to
                  providing you with individualized services to make your journey hassle-free, enjoyable, and
                  comfortable.
                </p>
                <ul className="ftco-footer-social list-unstyled float-md-left float-lft text-white">
                  <li className="ftco-animate">
                    <a href="#">
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a href="#">
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a href="#">
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md pt-5 border-left">
              <div className="ftco-footer-widget pt-md-5 mb-4 ml-md-5 text-white">
                <h2 className="ftco-heading-2 text-white" >Information</h2>
                <ul className="list-unstyled">
                  <li>
                    <a href="#" className="py-2 d-block text-white text-white">
                      Online Enquiry
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      General Enquiries
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Booking Conditions
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Privacy and Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Refund Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Call Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md pt-5 border-left">
              <div className="ftco-footer-widget pt-md-5 mb-4">
                <h2 className="ftco-heading-2 text-white">Experience</h2>
                <ul className="list-unstyled">
                  <li>
                    <Link to={'/marketarea'} className="py-2 d-block text-white">
                      market area
                    </Link>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Hotel and Restaurant
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Beach
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Nature
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Camping
                    </a>
                  </li>
                  <li>
                    <a href="#" className="py-2 d-block text-white">
                      Party
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md pt-5 border-left">
              <div className="ftco-footer-widget pt-md-5 mb-4">
                <h2 className="ftco-heading-2 text-white">Have a Questions?</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li className='text-white'>
                      <span className=" text-white">  Address: Allahabad Road, Near Airport Road</span>
                    </li>
                    <li>
                      <a href="#" className='text-white'>
                        <span className=" text-white">+91 7309088088</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className='text-white'>
                        <span className=" text-white">mohittourandtravels@gmail.com</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center text-white">
              <p className='text-white'>
                Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This
                 <i className="fa fa-heart" aria-hidden="true"></i> by{' '}
                <a href="" target="_blank" rel="noopener noreferrer">
                  Highxbrand
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
