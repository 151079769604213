import React from 'react'

const HotelServices = () => {
  return (
    <div>
        <h1>HotelServices</h1>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem ad ducimus natus voluptatibus modi quas expedita reiciendis amet aut quaerat asperiores, commodi inventore autem enim itaque eligendi hic. Nihil, nesciunt!
      
    </div>
  )
}

export default HotelServices
