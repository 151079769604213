import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'; // Default import
import Footer from './components/Footer'; // Default import
import Hero from './components/Hero'; // Optional, include on the home page
import CategorySection from './components/CategorySection'; // Category list
import CategoryProducts from './components/CategoryProducts'; // Products by category
import 'bootstrap/dist/css/bootstrap.min.css';
import './template/css/bootstrap/bootstrap-grid.css';
import './template/css/bootstrap/bootstrap-reboot.css';
import './template/css/animate.css';
import './template/css/bootstrap-datepicker.css';
import './template/css/bootstrap.min.css';
import './template/css/flaticon.css';
import './template/css/jquery.timepicker.css';
import './template/css/magnific-popup.css';
import './template/css/style.css';
import Home from './components/Home';
import ProductDetails from './components/ProductDetails';
import Dashboard from './components/dashboard/Dashboard';
import ManageProducts from './components/dashboard/ManageProducts';
import ProductsList from './components/ProductsList';
import MarketArea from './components/MarketArea';
import AboutUs from './components/AboutUs';
import CabServices from './components/CabServices';
import BikeServices from './components/BikeServices';
import HotelServices from './components/HotelServices';
import AirportServices from './components/AirportServices';
import SpecialDarshanServices from './components/SpecialDarshanServices';
import TravelAgencyServices from './components/TravelAgencyServices';
import DemoProductList from './components/DemoProductList';


function App() {
  return (
    <Router>
      <div>
        <Navbar />
        
        <Routes>
          {/* Home Page Route */}
          <Route path="/" element={<Home />} />

          {/* Category List Route */}
          <Route path="/categories" element={<CategorySection />} />
          <Route path="/aboutus" element={<AboutUs />} />

          <Route path="/cab" element={<CabServices />} />
          <Route path="/bike" element={<BikeServices />} />
          <Route path="/hotel" element={<HotelServices />} />
          <Route path="/airport" element={<AirportServices />} />
          <Route path="/special" element={<SpecialDarshanServices />} />
          <Route path="/travel" element={<TravelAgencyServices />} />

          <Route path="/productlist" element={<DemoProductList/>} />
          {/* Category Products Route */}
          <Route path="/category/:categoryId" element={<CategoryProducts />} />
          <Route path="/product/:productId" element={<ProductDetails/>} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/manageproduct" element={<ManageProducts />} />

          <Route path="/marketarea" element={<MarketArea />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
