import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ProductDetails = () => {
  const { productId } = useParams(); // Retrieve productId from URL
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch product data using the productId
    axios
      .get(`http://localhost:5000/product/${productId}`)
      .then((response) => {
        console.log('Product response:', response.data);
        setProduct(response.data);
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
      });
  }, [productId]);

  if (!product) return <div>Loading...</div>;

  // Safely access nested properties with optional chaining and null checks
  const itinerary = product.itinerary || [];
  const additionalInfo = product.additionalInfo || {};
  const highlights = additionalInfo.highlights || [];
  const contactDetails = additionalInfo.contactDetails || {};
  const { phone, email, address } = contactDetails;

  return (
    <div>
      <div className="container" style={{ marginTop: '150px', marginBottom: '200px' }}>
        <div className="row">
          <div className="col-md-6">
            <div className="card bg-none" style={{ height: '400px', width: '400px' }}>
              <img
                src={`http://localhost:5000/${product.images[0]}`}
                alt={product.name}
                style={{ height: '400px', width: '400px' }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <h1>{product.name}</h1>
            <p>{product.description}</p>
          </div>
        </div>

        {/* Itinerary Section */}
        {itinerary.length > 0 && (
          <div className="mt-5">
            <h2>Itinerary</h2>
            <hr />
            {itinerary.map((day, index) => (
              <div key={index} className="mb-4">
                <h3>Day {day.day}</h3>
                <ul>
                  {day.activities.map((activity, activityIndex) => (
                    <li key={activityIndex}>
                      <strong>{activity.title}</strong>: {activity.description}
                      {activity.optional && <span> (Optional)</span>}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        {/* Additional Information Section */}
        {(highlights.length > 0 || phone || email || address) && (
          <div className="mt-5">
            <h2>Additional Information</h2>
            <hr />
            {highlights.length > 0 && (
              <>
                <h4>Highlights:</h4>
                <ul>
                  {highlights.map((highlight, index) => (
                    <li key={index}>{highlight}</li>
                  ))}
                </ul>
              </>
            )}
            {contactDetails && (
              <>
                <h4>Contact Details:</h4>
                {phone && <p><strong>Phone:</strong> {phone}</p>}
                {email && <p><strong>Email:</strong> {email}</p>}
                {address && <p><strong>Address:</strong> {address}</p>}
              </>
            )}
          </div>
        )}
      </div>
<div className='container'>


      <h2>Book Your Journey Today!
</h2>
<hr/>
<p>
Let Mohit Tour and Travels will take you on a journey that you will always remember. Your vacation
will be ideal thanks to our carefully selected packages, careful attention to every detail, and a
commitment to offering top-notch service. Ready for an incredible experience in India? Make
reservations today!
</p></div>
    </div>
  );
};

export default ProductDetails;
