import React from 'react'
import bgImage from '../images/2151765561.jpg'
const BecomePartner = () => {
   
  return (
    <div>
      <section class="ftco-intro ftco-section ftco-no-pt">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-12 text-center">
						<div class="img"  style={{
        backgroundImage: `url(${bgImage})`,
       
      }}>
							<div class="overlay"></div>
							<h2>Partner with Mohit Tour & Travels</h2>
							<p>Join us to create unforgettable travel experiences! Collaborate with us to grow your business and delight customers across India.</p>
							<p class="mb-0"><a href="#" class="btn btn-primary px-4 py-3">Ask For A Quote</a></p>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default BecomePartner
