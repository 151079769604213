import React, { useState } from 'react';
import logo from "../images/logo.png"
import { Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
const Navbar = () => {
  // State for toggling the mobile navbar
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  // Toggle the navbar
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark  bg-white  sticky-top" id="ftco-navbar" style={{ backgroundColor: 'white' }}>
      <div className="container">
        <Link className="navbar-brand d-flex" to={'/'}>
          <img src={logo} className='brand' style={{ height: "100px" }} /> <p style={{ color: 'grey' }} className='pt-4 mt-2'> Mohit Tour and Travels</p>
        </Link>
        <button
          className="navbar-toggler bg-dark text-white"
          type="button"
          onClick={toggleNavbar}
          aria-controls="ftco-nav"
          aria-expanded={isNavbarOpen ? 'true' : 'false'}
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu"></span> Menu
        </button>

        <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link to="/" className="nav-link text-dark">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/aboutus" className="nav-link text-dark">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/productlist" className="nav-link text-dark">
                Our Tour Pacakges
              </Link>
            </li>
         
              <li className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle text-dark"
                  id="servicesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                  <li>
                    <Link to="/services/spiritual-tours" className="dropdown-item">
                      Spiritual Tours
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cultural-tours" className="dropdown-item">
                      Cultural Tours
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/premium-packages" className="dropdown-item">
                      Premium Packages
                    </Link>
                  </li>
                </ul>
              </li>
          
            {/* <li className="nav-item">
              <Link to="blog.html" className="nav-link text-dark">
                Blog
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="contact.html" className="nav-link text-dark">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
