import React from "react";
import img from "../images/banner/VaranasiBanner2.jpg"
const AboutUs = () => {
  return (
    <div className="about-us ">
        <img src={img} className="w-100"/>
        <div className="container pt-3 pb-3">
      <section className="about-intro ">
        
        <h1>About Us</h1>
        <p>
          Welcome to <strong>Mohit Tour &amp; Travels</strong>, your trusted travel partner for
          remarkable spiritual excursions around India. Customized trip packages to places like
          Ayodhya, Varanasi, Prayagraj, Chitrakoot, and Lucknow are our speciality. Our objective
          is to deliver a flawless trip experience with cosy lodging, reliable transportation, and
          expert advice.
        </p>
        <p>
          To meet your demands, we provide a variety of affordable packages, including both
          basic and premium alternatives. With years of expertise, we make sure every trip is
          meticulously organized so you may concentrate on the growth of your faith. Our
          committed staff makes sure your vacation is hassle-free by striving for excellence in
          customer satisfaction.
        </p>
        <p>
          Mohit Tour and Travels ensures a modified, engaging experience every time, whether
          you&#39;re travelling alone, with family, or in a group.
        </p>
      </section>

      <section className="mission">
        <h2>Our Mission</h2>
        <p>
          Our mission is simple: to offer our customers the best spiritual locations in India while
          emphasizing cost, comfort, and ease. Our priority is to make it an enjoyable experience
          that blends comfort, cultural immersion, and spiritual connection, whether planning an
          entire family trip or a single trip. We put the needs and desires of our customers first
          and go above and beyond to make every trip one to remember.
        </p>
      </section>

      <section className="vision">
        <h2>Our Vision</h2>
        <p>
          Our vision at <strong>Mohit Tour &amp; Travels</strong> is to be the leading provider of unique spiritual
          journeys across India, providing our customers with remarkable experiences that uplift
          and rejuvenate them. By making every journey a meaningful trip, we hope to establish
          lasting connections between travellers and India&#39;s rich cultural and spiritual legacy. We
          hope to be the go-to partner for all those looking for a more serious, engaging travel
          experience through integrating cost, comfort, and first-rate service.
        </p>
      </section>

      <section className="join-us">
        <h2>Join Us on a Spiritual Journey</h2>
        <p>
          <strong>Mohit Tour and Travels</strong> are ready to help, whether your goal is a deeply spiritual
          experience, a cultural excursion, or a tranquil retreat. Check out our travel packages
          and join us for a spiritual journey right now. While you enjoy the adventure, let us take
          care of all of the details.
        </p>
      </section>

      <section className="contact-us">
        <h2>Contact Us</h2>
        <p>Have questions or need help planning your next trip? Get in touch with us today!</p>
        <ul>
          <li><strong>Phone:</strong> 7309088088</li>
          <li><strong>Email:</strong> mohittourandtravels@gmail.com</li>
          <li><strong>Address:</strong> Allahabad Road, Near Airport Road</li>
        </ul>
      </section>
    </div></div>
  );
};

export default AboutUs;
