import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const apiUrl = 'http://localhost:5000/category';
  const AWS_S3_BASE_URL = "https://mohittour1423.s3.us-east-1.amazonaws.com/";

  // States for the form fields
  const [categoryName, setCategoryName] = useState('');
  const [categoryImages, setCategoryImages] = useState(null);
  const [categories, setCategories] = useState([]);
  const [updateCategoryId, setUpdateCategoryId] = useState('');
  const [updateCategoryName, setUpdateCategoryName] = useState('');
  const [updateCategoryImages, setUpdateCategoryImages] = useState(null);
  const [deleteCategoryId, setDeleteCategoryId] = useState('');

  // Fetch Categories
  const handleFetchCategories = async () => {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      // Map over categories to include full S3 image URLs
      const updatedCategories = data.map(category => ({
        ...category,
        images: category.images.map(imagePath =>
          AWS_S3_BASE_URL + imagePath.replace(/\\/g, '/')
        ),
      }));

      setCategories(updatedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch categories on component mount
  useEffect(() => {
    handleFetchCategories();
  }, []);

  // Add Category
  const handleAddCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', categoryName);

    if (categoryImages) {
      Array.from(categoryImages).forEach((file) => {
        formData.append('images', file);
      });
    }

    try {
      const response = await fetch(`${apiUrl}/add`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      alert(result.message || 'Category added successfully!');
      setCategoryName('');
      setCategoryImages(null);
      handleFetchCategories(); // Refresh category list after adding
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  // Update Category
  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', updateCategoryName);

    if (updateCategoryImages) {
      Array.from(updateCategoryImages).forEach((file) => {
        formData.append('images', file);
      });
    }

    try {
      const response = await fetch(`${apiUrl}/update/${updateCategoryId}`, {
        method: 'PUT',
        body: formData,
      });
      const result = await response.json();
      alert(result.message || 'Category updated successfully!');
      setUpdateCategoryId('');
      setUpdateCategoryName('');
      setUpdateCategoryImages(null);
      handleFetchCategories(); // Refresh category list after updating
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  // Delete Category
  const handleDeleteCategory = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/delete/${deleteCategoryId}`, {
        method: 'DELETE',
      });
      const result = await response.json();
      alert(result.message || 'Category deleted successfully!');
      setDeleteCategoryId('');
      handleFetchCategories(); // Refresh category list after deleting
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  return (
    <div className='container' style={{ marginTop: '200px' }}>
      <h1>Welcome to the Dashboard</h1>
      <p>This is the main dashboard page.</p>
      <div className='row'>
        <div className='col-md-4'>
          <Link to="/dashboard">
            <button className='btn btn-danger mb-3'>Manage Category</button>
          </Link>
          <br />
          <Link to="/manageproduct">
            <button className='btn btn-danger'>Manage Packages</button>
          </Link>
          <br />
          <Link to="/manageproduct">
            <button className='btn btn-danger'>Manage Market Area</button>
          </Link>
        </div>
        <div className='container col-md-8'>
          <h1>Category Management</h1>

          {/* Add Category */}
          <form onSubmit={handleAddCategory} className='form'>
            <h2>Add Category</h2>
            <input
              type='text'
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder='Category Name'
              required
            />
            <input
              type='file'
              onChange={(e) => setCategoryImages(e.target.files)}
              multiple
            />
            <button type='submit'>Add Category</button>
          </form>

          {/* Update Category */}
          <form onSubmit={handleUpdateCategory} className='form'>
            <h2>Update Category</h2>
            <select
              value={updateCategoryId}
              onChange={(e) => setUpdateCategoryId(e.target.value)}
              required
            >
              <option value='' disabled selected>
                Select Category
              </option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            <input
              type='text'
              value={updateCategoryName}
              onChange={(e) => setUpdateCategoryName(e.target.value)}
              placeholder='New Name'
            />
            <input
              type='file'
              onChange={(e) => setUpdateCategoryImages(e.target.files)}
              multiple
            />
            <button type='submit'>Update Category</button>
          </form>

          {/* Delete Category */}
          <form onSubmit={handleDeleteCategory} className='form'>
            <h2>Delete Category</h2>
            <select
              value={deleteCategoryId}
              onChange={(e) => setDeleteCategoryId(e.target.value)}
              required
            >
              <option value='' disabled selected>
                Select Category
              </option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            <button type='submit'>Delete Category</button>
          </form>

          {/* Display Categories */}
          <div className='categories'>
            <h2>All Categories</h2>
            <button onClick={handleFetchCategories}>Fetch Categories</button>
            <ul id='categories-list'>
              {categories.map((category) => (
                <li key={category._id}>
                  <h3>{category.name} (ID: {category._id})</h3>
                  {category.images && category.images.map((image, index) => (
                    <img key={index} src={image} alt={category.name} width="100" />
                  ))}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
