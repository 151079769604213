import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Card, Row, Col } from 'react-bootstrap';

const CategoryProducts = () => {
  const { categoryId } = useParams(); // Get category ID from the URL
  const [products, setProducts] = useState([]);

  // Fetch products for the selected category
  useEffect(() => {
    axios.get(`http://localhost:5000/product?categoryId=${categoryId}`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, [categoryId]);

  return (
    <div className="container mt-5" style={{ paddingTop: '100px' }}>
      <h2 className="text-center mb-4">Products for Selected Category</h2>
      <Row>
        {products.map(product => (
          <Col key={product._id} xs={12} sm={6} md={4} lg={4} className="mb-4">
            <Card className="border-0">
              <Card.Img
                variant="top"
                className="img"
                src={`http://localhost:5000/${product.images[0].replace(/\\/g, '/')}`}
              />
              <Card.Body>
                <div className="text p-4">
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>{product.description}</Card.Text>
                  <Link to={`/product/${product._id}`} className="btn btn-primary">
                    View Details
                  </Link>
                  <Link to={`/buy/${product._id}`} className="btn btn-success ml-2">
                    Buy Now
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CategoryProducts;
