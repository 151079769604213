import React from 'react'

const TravelAgencyServices = () => {
  return (
    <div>
        <h1>TravelAgencyServices</h1>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam sint possimus maxime iusto error reprehenderit amet voluptatem odit animi quae dolorem veritatis corrupti reiciendis, nihil, voluptatum similique esse nam iste.
    </div>
  )
}

export default TravelAgencyServices
