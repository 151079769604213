import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ManageProducts = () => {
  const apiUrl = 'http://localhost:5000';

  // State for managing categories, products, and form fields
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: '',
    price: '',
    description: '',
    categoryId: '',
    images: null,
  });
  const [updateProduct, setUpdateProduct] = useState({
    id: '',
    name: '',
    price: '',
    description: '',
    categoryId: '',
    images: null,
  });
  const [deleteProductId, setDeleteProductId] = useState('');

  // Fetch categories and products
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${apiUrl}/category`);
        const data = await response.json();
        setCategories(data);
        console.log(categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch(`${apiUrl}/product`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchCategories();
    fetchProducts();
  }, []);

  // Fetch the selected product's details when updating
  useEffect(() => {
    if (updateProduct.id) {
      const fetchProductDetails = async () => {
        try {
          const response = await fetch(`${apiUrl}/product/${updateProduct.id}`);
          const product = await response.json();
          setUpdateProduct({
            id: product._id,
            name: product.name,
            price: product.price,
            description: product.description,
            categoryId: product.categoryId,
            images: null, // Images are not retrieved; assume they are already on the server
          });
        } catch (error) {
          console.error('Error fetching product details:', error);
        }
      };

      fetchProductDetails();
    }
  }, [updateProduct.id]);

  // Handle adding a new product
  const handleAddProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', newProduct.name);
    formData.append('price', newProduct.price);
    formData.append('description', newProduct.description);
    formData.append('categoryId', newProduct.categoryId);
    if (newProduct.images) {
      Array.from(newProduct.images).forEach((file) => formData.append('images', file));
    }

    try {
      const response = await fetch(`${apiUrl}/product/add`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      alert(data.message || 'Product added successfully');
      setNewProduct({
        name: '',
        price: '',
        description: '',
        categoryId: '',
        images: null,
      });
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Failed to add product.');
    }
  };

  // Handle updating a product
  const handleUpdateProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', updateProduct.name);
    formData.append('price', updateProduct.price);
    formData.append('description', updateProduct.description);
    formData.append('categoryId', updateProduct.categoryId);
    if (updateProduct.images) {
      Array.from(updateProduct.images).forEach((file) => formData.append('images', file));
    }

    try {
      const response = await fetch(`${apiUrl}/product/update/${updateProduct.id}`, {
        method: 'PUT',
        body: formData,
      });
      const data = await response.json();
      alert(data.message || 'Product updated successfully');
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Failed to update product.');
    }
  };

  // Handle deleting a product
  const handleDeleteProduct = async (e) => {
    e.preventDefault();

    if (!deleteProductId) {
      alert('Please select a product to delete.');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/product/delete/${deleteProductId}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      alert(data.message || 'Product deleted successfully');
      setDeleteProductId('');
    } catch (error) {
      console.error('Error deleting product:', error);
      alert('Failed to delete product.');
    }
  };

  return (
    <div className="container" style={{ marginTop: '200px' }}>
      <div className="row">
        <div className="col-md-4">
          <Link to="/dashboard">
            <button className="btn btn-danger mb-3">Manage Category</button>
          </Link>
          <br />
          <Link to="/manageproduct">
            <button className="btn btn-danger">Manage Packages</button>
          </Link>
        </div>
        <div className="col-md-8">
          <h1>Manage Products</h1>

          {/* Add Product Form */}
          <form onSubmit={handleAddProduct}>
            <h2>Add Product</h2>
            <input
              type="text"
              value={newProduct.name}
              onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
              placeholder="Product Name"
              required
            />
            <input
              type="number"
              value={newProduct.price}
              onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
              placeholder="Price"
              required
            />
            <textarea
              value={newProduct.description}
              onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
              placeholder="Description"
            />
            <select
              value={newProduct.categoryId}
              onChange={(e) => setNewProduct({ ...newProduct, categoryId: e.target.value })}
            >
              <option value="">Select a Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            <input
              type="file"
              onChange={(e) => setNewProduct({ ...newProduct, images: e.target.files })}
              multiple
            />
            <button type="submit">Add Product</button>
          </form>

          {/* Update Product Form */}
          <form onSubmit={handleUpdateProduct}>
            <h2>Update Product</h2>
            <select
              value={updateProduct.id}
              onChange={(e) => setUpdateProduct({ ...updateProduct, id: e.target.value })}
            >
              <option value="">Select a Product</option>
              {products.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={updateProduct.name || ''}
              onChange={(e) => setUpdateProduct({ ...updateProduct, name: e.target.value })}
              placeholder="New Product Name"
            />
            <input
              type="number"
              value={updateProduct.price || ''}
              onChange={(e) => setUpdateProduct({ ...updateProduct, price: e.target.value })}
              placeholder="New Price"
            />
            <textarea
              value={updateProduct.description || ''}
              onChange={(e) => setUpdateProduct({ ...updateProduct, description: e.target.value })}
              placeholder="New Description"
            />
            <select
              value={updateProduct.categoryId || ''}
              onChange={(e) => setUpdateProduct({ ...updateProduct, categoryId: e.target.value })}
            >
              <option value="">Select a New Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            <input
              type="file"
              onChange={(e) => setUpdateProduct({ ...updateProduct, images: e.target.files })}
              multiple
            />
            <button type="submit">Update Product</button>
          </form>

          {/* Delete Product Form */}
          <form onSubmit={handleDeleteProduct}>
            <h2>Delete Product</h2>
            <select
              value={deleteProductId}
              onChange={(e) => setDeleteProductId(e.target.value)}
            >
              <option value="">Select a Product</option>
              {products.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name}
                </option>
              ))}
            </select>
            <button type="submit">Delete Product</button>
          </form>

          <h2>All Products</h2>
          <ul>
            {products.map((product) => (
              <li key={product._id}>
                {product.name} (ID: {product._id}) - ${product.price}
              </li>
            ))}
          </ul>
        </div>
      </div>
      
    </div>
  );
};

export default ManageProducts;
