import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import banner1 from '../images/banner/banner_ayodhya.webp'
import banner2 from '../images/banner/VaranasiBanner2.jpg'
import banner3 from '../images/banner/chirkoot.jpg'

import AboutUsSection from './AboutUsSection.js';
import CategorySection from './CategorySection.js';
import BecomePartner from './BecomePartner.js';
import Testimonial from './Testimonial.js';
import OurServices from './OurServices.js';
import ProductsList from './ProductsList.js';
import DemoProductList from './DemoProductList.jsx';

const Hero = () => {
  return (
   <div>
    <Carousel fade>
      <Carousel.Item>
        <img src={banner1} style={{height:'582px'}} className='w-100 banner-img'/>
        <Carousel.Caption className='caption-center'>
          <h1 className=' banner-text-h1' >Ayodhya The Birthplace Of Lord Ram</h1>
          <hr/>
          <p className='banner-text-p px-5 pt-2'> Ayodhya, the birthplace of Lord Ram, is a sacred city steeped in spiritual and cultural significance. It is home to iconic temples like Ram Janmabhoomi and Hanuman Garhi, attracting pilgrims from all over the world.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={banner2} style={{height:'582px'}} className='w-100 banner-img' />
      <Carousel.Caption className='caption-center'>
          <h1 className=' banner-text-h1' >Varanasi The Spiritual Capital Of India</h1>    <hr/>
          <p className='banner-text-p px-5 pt-2'>Known as the spiritual capital of India, Varanasi is famous for its ghats along the River Ganges, ancient temples, and vibrant culture. Key attractions include Kashi Vishwanath Temple and the mesmerizing Ganga Aarti. </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={banner3}  style={{height:'582px'}} className='w-100 banner-img'/>
      <Carousel.Caption className='caption-center'>
          <h1 className=' banner-text-h1' > Prayagraj The Confluence Of Three Holy Rivers</h1>    <hr/>
          <p className='banner-text-p px-5 pt-2'>Prayagraj, formerly Allahabad, is renowned for the Triveni Sangam, the confluence of three holy rivers—Ganga, Yamuna, and Saraswati. It is also the site of the grand Kumbh Mela and rich in historical and cultural heritage.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

    <AboutUsSection/>
        {/* <CategorySection/> */}
        <DemoProductList/>
        <OurServices/>
        <BecomePartner/>
        {/* <Testimonial/> */}
   </div>
  )
}

export default Hero
