import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
  import "./style/ourservices.css"
import { Link } from 'react-router-dom';

const OurServices = () => {
    
// Service Data
const services = [
  
    {
      title: 'Hotels/Cabs: ',
      description: 'Find your perfect stay with our extensive network of hotels worldwide. From budget-friendly options to luxurious hotels, we deal from 2-star to 5-star properties with palatable meal plans.',
      icon: '🛏️ 🚗',
    },
   
   
    {
      title: 'Special Darshan',
      description: 'We provide Special Darshan, seamless VIP check-ins, and exceptional arrangements of the Ultimate Mangala arti ceremony if specially demanded by our esteemed customers.',
      icon: '🛕',
    },
    {
      title: 'Travel Agency:',
      description: 'For all of your travel requirements, get expert advice and personalized help. We can efficiently assist you with everything, from trip planning and accommodations to booking flights effortlessly.',
      icon: '🚍',
    },
    {
      title: 'Hotel Airport Transfer:',
      description: 'Easy transportation to and from the airport ensures a seamless beginning to your trip.',
      icon: '✈️',
    },
  
    {
      title: 'Bike Rental',
      description: 'Rent bikes to explore the area or have a thrilling ride along scenic local roads.',
      icon: '🏍️',
    },
  
    
  ];
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Our Services</h2>
      <Row className='d-flex justify-content-center'>
        {services.map((service, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={4} className="mb-4">
           <Link to="/"> <Card className="border-0 text-center service-card" >
              <div className="icon" style={{ fontSize: '3rem', color: '#FFA500' , }}>
                {service.icon}
              </div>
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card></Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OurServices;
